.aws-slider {
  --slider-height-percentage: 16%;
  --slider-transition-duration: 1000ms;
  --organic-arrow-thickness: 1px;
  --organic-arrow-border-radius: 0px;
  --organic-arrow-height: 10px;
  --organic-arrow-color: #838383;
  --control-button-width: 5%;
  --control-button-height: 25%;
  --control-button-background: transparent;
  --control-bullet-color: #9a9a9a;
  --control-bullet-active-color: #838383;
  --loader-bar-color: #851515;
  --loader-bar-height: 1px;
}
