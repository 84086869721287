:root {
  font-size: var(--root-font-size);
  line-height: var(--line-height-text);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-feature-settings: kern 1, liga 1;
  -moz-font-feature-settings: kern=1, liga=1;
  -moz-font-feature-settings: kern on, liga on;
  -ms-font-feature-settings: kern 1, liga 1;
  -o-font-feature-settings: kern, liga;
  font-feature-settings: kern, liga;
  font-family: var(--font-family);
  background: var(--white-palette);
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  color: var(--text-body-color);
}
@media screen and (min-width: 768px) {
  :root {
    --root-font-size: 54.6875%;
  }
}
@media screen and (min-width: 850px) {
  :root {
    --root-font-size: 62.5%;
  }
}
body {
  margin: var(--spacing-clear);
  font-size: var(--font-size-text);
  min-height: 100vh;
}
button,
input,
optgroup,
select,
textarea {
  font-family: var(--font-family);
  font-size: var(--font-size-text);
  line-height: var(--line-height-text);
}
input,
optgroup,
select,
textarea {
  margin: var(--spacing-clear);
}

:focus-visible,
button:focus-visible,
input:focus-visible,
textarea:focus-visible,
select:focus-visible,
a:focus-visible,
summary:focus-visible {
  outline: var(--border-thin-ridge);
}

img {
  border-style: none;
  -ms-interpolation-mode: bicubic;
}
ul,
ol {
  list-style-position: inside;
}
progress {
  vertical-align: baseline;
}
summary {
  display: list-item;
}
template,
[hidden],
audio:not([controls]) {
  display: none;
}
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
hr,
main,
nav,
section,
summary {
  display: block;
}
h1,
h2,
h3,
h4,
h5,
h6,
li,
p,
small,
header,
table,
figure,
footer,
details,
menu,
pre,
section,
article,
dl,
menu,
ul,
ol,
aside,
div,
hr,
fieldset,
textarea,
keygen,
select,
input {
  width: 100%;
}
audio,
canvas,
mark,
video {
  display: inline-block;
}
hr,
img,
legend {
  border: var(--spacing-clear);
}
body,
figure,
form {
  margin: var(--spacing-clear);
}
a:active,
a:hover {
  outline: var(--spacing-clear);
}
audio,
canvas,
img,
video {
  vertical-align: middle;
}
audio:not([controls]) {
  height: var(--spacing-clear);
}
abbr[title] {
  border-bottom: var(--border-thin-dotted);
}
blockquote {
  margin: var(--spacing-medium) var(--spacing-clear);
  font-style: italic;
}
dfn {
  font-style: italic;
}
mark {
  background: var(--mark-background);
  color: var(--mark-color);
}
q {
  quotes: none;
}
q:after,
q:before {
  content: "";
  content: none;
}
abbr[title] {
  border-bottom: none;
  text-decoration: underline;
  text-decoration: underline dotted;
}
dd {
  margin: 0;
}
svg:not(:root) {
  overflow: hidden;
}
*,
:before,
:after {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
::-moz-selection,
::selection {
  background: var(--dark-grey-palette);
  color: var(--selection-text-color);
  text-shadow: none;
}
button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
}
button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}
button:-moz-focusring,
[type="button"]:-moz-focusring,
[type="reset"]:-moz-focusring,
[type="submit"]:-moz-focusring {
  outline: 1px dotted ButtonText;
}
[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto;
}
[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}
[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}
::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}
button,
html input[type="button"],
input[type="reset"],
input[type="submit"] {
  -webkit-appearance: button;
  cursor: pointer;
}
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}
button::-moz-focus-inner,
input::-moz-focus-inner {
  border: 0;
  padding: 0;
}
[type="checkbox"],
[type="radio"],
hr,
legend {
  padding: 0;
}
button,
input {
  overflow: visible;
}
button,
select {
  text-transform: none;
}
button[disabled],
html input[disabled] {
  cursor: default;
}
input[type="checkbox"],
input[type="radio"] {
  padding: 0;
}
em {
  font-style: italic;
}
b,
strong {
  font-weight: 700;
}
hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
  border: none;
  border-top: var(--border-thin);
}
pre,
code,
kbd,
samp {
  font-family: monospace;
}
pre {
  white-space: pre-wrap;
  word-wrap: break-word;
}
sub,
sup {
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}
input,
textarea {
  -webkit-appearance: none;
}
input[type="radio"] {
  -webkit-appearance: radio;
}
input[type="checkbox"] {
  -webkit-appearance: checkbox;
}
input[type="button"],
input[type="submit"],
input[type="reset"],
button {
  display: inline-block;
  cursor: pointer;
  width: auto;
}
button,
input,
optgroup,
select,
textarea {
  vertical-align: baseline;
}
table {
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
}
label {
  display: block;
}
textarea {
  resize: vertical;
  overflow: auto;
  vertical-align: top;
}
legend {
  font-weight: 700;
}
input[type="checkbox"],
input[type="radio"] {
  width: auto;
}
